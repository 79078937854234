import { useMemo, useState } from "react";

import { PlayerAPI } from "@/api";
import { useExportHandler } from "@/hooks";
import Query from "@/models/query";

type ExportParams = (
  | { clientId: number }
  | { agentId: number }
  | { hallId: number }
) & {
  includeDeleted?: boolean;
  searchQuery?: string;
};

function usePlayerExport({
  agentId,
  subAgentId,
  hallId,
  clientId,
  searchQuery,
  includeDeleted,
}: {
  agentId?: number;
  hallId?: number;
  clientId?: number;
  searchQuery?: string;
  includeDeleted?: boolean;
  subAgentId?: number;
}) {
  const [exportQuery] = useState(() => new Query(PlayerAPI.export));

  const filterParams = useMemo(() => {
    let params;

    if (hallId) {
      params = {
        hallId,
      };
    } else if (agentId) {
      params = {
        agentId,
      };
    } else if (subAgentId) {
      params = {
        agentId: subAgentId,
      };
    } else if (clientId) {
      params = {
        clientId,
      };
    }

    if (searchQuery) {
      params = {
        ...params,
        searchQuery,
      };
    }

    if (includeDeleted) {
      params = {
        ...params,
        includeDeleted,
      };
    }

    return params;
  }, [agentId, clientId, hallId, includeDeleted, searchQuery, subAgentId]);

  const handleExportClick = useExportHandler({
    exportQuery,
    params: filterParams as ExportParams,
    fileName: "players.csv",
  });

  return {
    exportQuery,
    handleExportClick,
  };
}

export default usePlayerExport;

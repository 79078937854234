import { useCallback } from "react";

import Query from "@/models/query";

type ExtractQueryInput<T> = T extends Query<infer Input, { data: string }>
  ? Input extends Record<string, unknown>
    ? Omit<Input, "format">
    : never
  : never;

interface ExportHandlerProps<T extends Query<any, any>> {
  exportQuery: T;
  params?: ExtractQueryInput<T>;
  fileName: string;
}

export function useExportHandler<T extends Query<any, any>>({
  exportQuery,
  params,
  fileName,
}: ExportHandlerProps<T>) {
  return useCallback(async () => {
    await exportQuery.submit({ format: "csv", ...params });

    if (!exportQuery.isFulfilled) {
      return;
    }

    const { data } = exportQuery.data;
    const blob = new Blob([data], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  }, [exportQuery, fileName, params]);
}
